<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Membership List</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    placeholder="Search Text"
                    v-model="search_term"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <!-- <div class="col-2">
                    <div class="form-group">
                      <label for=""><b>User Type</b></label>
                      <select name="" id="" v-model="user_type" class="form-control form-control-sm">
                        <option value="all">All</option>
                        <option value="SUPERUSER">SUPERUSER</option>
                          <option value="trainer">trainer</option>
                          <option value="student">student</option>
                      </select>
                    </div>
                  </div> -->
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + Add Plan
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th
                    :class="{
                      active_sort: sort_by == 'title' || sort_by == '-title',
                    }"
                    @click="sortBy('title')"
                  >
                    PLAN NAME
                    <i
                      :class="{
                        'fa-sort': sort_by != 'title',
                        'fa-sort-up': sort_by == 'title',
                        'fa-sort-down': sort_by == '-title',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'price' || sort_by == '-price',
                    }"
                    @click="sortBy('price')"
                  >
                    price
                    <i
                      :class="{
                        'fa-sort': sort_by != 'price',
                        'fa-sort-up': sort_by == 'price',
                        'fa-sort-down': sort_by == '-price',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'order' || sort_by == '-order',
                    }"
                    @click="sortBy('order')"
                  >
                    rank
                    <i
                      :class="{
                        'fa-sort': sort_by != 'order',
                        'fa-sort-up': sort_by == 'order',
                        'fa-sort-down': sort_by == '-order',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort:
                        sort_by == 'validity' || sort_by == '-validity',
                    }"
                    @click="sortBy('validity')"
                  >
                    validity
                    <i
                      :class="{
                        'fa-sort': sort_by != 'validity',
                        'fa-sort-up': sort_by == 'validity',
                        'fa-sort-down': sort_by == '-validity',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort:
                        sort_by == 'start_date' || sort_by == '-start_date',
                    }"
                    @click="sortBy('start_date')"
                  >
                    start date
                    <i
                      :class="{
                        'fa-sort': sort_by != 'start_date',
                        'fa-sort-up': sort_by == 'start_date',
                        'fa-sort-down': sort_by == '-start_date',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort:
                        sort_by == 'end_date' || sort_by == '-end_date',
                    }"
                    @click="sortBy('end_date')"
                  >
                    end date
                    <i
                      :class="{
                        'fa-sort': sort_by != 'end_date',
                        'fa-sort-up': sort_by == 'end_date',
                        'fa-sort-down': sort_by == '-end_date',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.title }}</td>
                      <td><span class="fa fa-inr"></span>{{ item.price }}</td>
                      <td>{{ item.order }}</td>
                      <td>{{ item.validity }} days</td>
                      <td>{{ item.start_date }}</td>
                      <td>{{ item.end_date }}</td>
                      <td>
                        <span class="text-success" v-if="item.status"
                          ><b>Active</b></span
                        >
                        <span class="text-warning" v-else><b>INACTIVE</b></span>
                        <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        />
                      </td>
                    </tr>
                  </template>
                </template>
                <footer-table
                  :data="dataList"
                  :loading="loading_list"
                  :pageSize="pageSize"
                  @action="handleTableAction"
                  cols="6"
                ></footer-table>
              </tbody>
            </table>
          </div>
        </div>
        <div class="parent margin-top-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.id" class="text-center">Add New Plan</h4>
                <h4 v-if="payload.id" class="text-center">Update Plan</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Plan Name</b></label>
                      <input
                        type="text"
                        v-model="payload.title"
                        class="form-control form-control-sm"
                        placeholder="Plan Name"
                      />
                      <span class="error" v-if="error && error.title">{{
                        error.title
                      }}</span>
                    </div>
                  </div>
                  <!-- <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Description</b></label>
                      <input
                        type="text"
                        v-model="payload.description"
                        class="form-control form-control-sm"
                        placeholder="Description"
                      />
                      <span class="error" v-if="error && error.description">{{
                        error.description
                      }}</span>
                    </div>
                  </div> -->
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Plan For</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.user_type"
                        class="form-control form-control-sm"
                      >
                        <option value="all">All</option>
                        <option value="student">Student</option>
                        <option value="teacher">Teacher</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Display Price</b></label>
                      <input
                        type="number"
                        v-model="payload.original_price"
                        class="form-control form-control-sm"
                        placeholder="Display Price"
                      />
                      <span class="error" v-if="error && error.original_price">{{
                        error.original_price
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Actual Price</b></label>
                      <input
                        type="number"
                        v-model="payload.price"
                        class="form-control form-control-sm"
                        placeholder="Actual Price"
                      />
                      <span class="error" v-if="error && error.price">{{
                        error.price
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Rank</b></label>
                      <input
                        type="number"
                        v-model="payload.order"
                        class="form-control form-control-sm"
                        placeholder="Rank"
                      />
                      <span class="error" v-if="error && error.order">{{
                        error.order
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Validity in days</b></label>
                      <input
                        type="number"
                        v-model="payload.validity"
                        class="form-control form-control-sm"
                        placeholder="Validity in days"
                      />
                      <span class="error" v-if="error && error.validity">{{
                        error.validity
                      }}</span>
                    </div>
                  </div>
                  <!-- <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Bonus Coins</b></label>
                      <input
                        type="number"
                        v-model="payload.credits"
                        class="form-control form-control-sm"
                        placeholder="Bonus Coins"
                      />
                      <span class="error" v-if="error && error.credits">{{
                        error.credits
                      }}</span>
                    </div>
                  </div> -->
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Coin Discount for Enquiry Unlock in %</b></label>
                      <input
                        type="number"
                        v-model="payload.rules.discount"
                        class="form-control form-control-sm"
                        placeholder="Coin Discount"
                      />
                      <span class="error" v-if="error && error.discount">{{
                        error.discount
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Free Announcement unlocks</b></label>
                      <input
                        type="number"
                        v-model="payload.rules.free_announcement_unlocks"
                        class="form-control form-control-sm"
                        placeholder="Free Announcement unlocks"
                      />
                      <span class="error" v-if="error && error.free_announcement_unlocks">{{
                        error.free_announcement_unlocks
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Coins Expiry in days</b></label>
                      <input
                        type="number"
                        v-model="payload.rules.expiry"
                        class="form-control form-control-sm"
                        placeholder="Coins Expiry in days"
                      />
                      <span class="error" v-if="error && error.expiry">{{
                        error.expiry
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Enquiry Alert delay in mins</b></label>
                      <input
                        type="number"
                        v-model="payload.rules.delay"
                        class="form-control form-control-sm"
                        placeholder="Enquiry Alert delay in mins"
                      />
                      <span class="error" v-if="error && error.delay">{{
                        error.delay
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Start Date</b></label>
                      <input
                        type="date"
                        v-model="payload.start_date"
                        class="form-control form-control-sm"
                        placeholder="start_date"
                      />
                      <span class="error" v-if="error && error.start_date">{{
                        error.start_date
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>End Date</b></label>
                      <input
                        type="date"
                        v-model="payload.end_date"
                        class="form-control form-control-sm"
                        placeholder="end_date"
                      />
                      <span class="error" v-if="error && error.end_date">{{
                        error.end_date
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <option :value="true">Active</option>
                        <option :value="false">InActive</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for=""><b>Description Lines</b></label>
                      <template v-for="(item, index) of payload.options" :key="index">
                        <div style="display: flex;">
                          <input
                            type="text"
                            v-model="payload.options[index]"
                            class="form-control form-control-sm margin-bottom-5"
                            placeholder="Enter Line"
                          />
                          <span @click="removingOption(index)" class="fa fa-trash margin-left-10 margin-top-5 text-danger c-pointer"></span>
                        </div>
                      </template>
                      <div class="pad-top-10">
                        <button class="btn btn-sm btn-primary" @click="payload.options.push('')">+ Add</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <label for=""><b>Pricing</b></label>
                  </div>
                  <template v-for="(item, idx) in regionsList" :key="idx">
                    <div class="col-6 pad-0">
                      <div class="row margin-5 pad-5" style="border: 1px dashed #ddd;">
                        <div class="col pad-5">
                          <div class="form-group">
                            <label for="">Currency</label><br>
                            {{item.id}}
                          </div>
                        </div>
                         <div class="col pad-5">
                           <div class="form-group">
                              <label for="">Original Price</label>
                              <input
                                type="number"
                                v-model="payload.pricing[item.id].original_price"
                                class="form-control form-control-sm"
                                placeholder="Display Price"
                              />
                           </div>
                        </div>
                        <div class="col pad-5">
                          <div class="form-group">
                            <label for="">Actual Price</label>
                            <input
                              type="number"
                              v-model="payload.pricing[item.id].price"
                              class="form-control form-control-sm"
                              placeholder="Actual Price"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.id"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New Plan
                    </button>
                    <button
                      v-if="payload.id"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Plan
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "plan",
      defaultPayload: {
        title: null,
        description: null,
        options: [],
        user_type: "all",
        price: null,
        original_price: null,
        credits: 0,
        order: 1,
        validity: null,
        start_date: null,
        end_date: null,
        addons: null,
        rules: {
          discount: 0,
          expiry: 0,
          delay: 0,
          free_announcement_unlocks: 0,
        },
        data: null,
        status: true,
        pricing: {}
      },
    };
  },
  mixins: [DataMixin],
  created() {
    this.getRegionsList();
  },
  methods: {
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      // if (
      //   that.payload.description === null ||
      //   that.payload.description === ""
      // ) {
      //   this.error.description = "Description is required";
      //   proceed = false;
      // }
      if (that.payload.price === null || that.payload.price === "") {
        this.error.price = "Price is required";
        proceed = false;
      }
      // if (that.payload.credits === null || that.payload.credits === "") {
      //   this.error.credits = "Go Coins is required";
      //   proceed = false;
      // }
      if (that.payload.validity === null || that.payload.validity === "") {
        this.error.validity = "Validity is required";
        proceed = false;
      }
      if (that.payload.order === null || that.payload.order === "") {
        this.error.order = "Order is required";
        proceed = false;
      }
      if (that.payload.title === null || that.payload.title === "") {
        this.error.title = "Title is required";
        proceed = false;
      }
      if(proceed) {
        that.payload.start_date = that.$moment(that.payload.start_date).format('MM-DD-YYYY');
        that.payload.end_date = that.$moment(that.payload.end_date).format('MM-DD-YYYY');
      }
      return proceed;
    },
    dataEdit(item) {
      this.payload =Object.assign({}, item);
      if(this.payload.rules === null) {
        this.payload.rules = {
          discount: 0,
          expiry: 0,
          delay: 0,
        }
      }
      if(this.payload.pricing === null) {
        this.payload.pricing = {};
        this.regionsList.forEach(item => {
          this.payload.pricing[item.id] = {
            original_price: 0,
            price: 0,
          }
        });
      } else {
        this.regionsList.forEach(item => {
          if(!this.payload.pricing[item.id]) {
            this.payload.pricing[item.id] = {
              original_price: 0,
              price: 0,
            }
          }
        });
      }
      this.visible_form = true;
    },
    resetPayload() {
      const defaultPayload = this.defaultPayload;
      this.payload = Object.assign({}, defaultPayload);
      this.error = {};
      this.regionsList.forEach(item => {
        this.payload.pricing[item.id] = {
          original_price: 0,
          price: 0,
        }
      });
    },
    removingOption(index) {
      this.payload.options.splice(index, 1);
    }
  },
};
</script>
<style lang="stylus" scoped></style>